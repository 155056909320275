@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Rubik", sans-serif;
}

section {
  margin: 0;
  padding: 0;
}

nav a {
  @apply text-lg font-medium;
}
.swiper-button-next,
.swiper-button-prev {
  color: #2563eb;
}

.swiper-pagination-bullet-active {
  background: #2563eb;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 20px;
}

.swiper-pagination {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
}
html {
  scroll-behavior: smooth;
}
.no-outline:focus {
  outline: none;
}
